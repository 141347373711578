.thumbnailfile-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.on-row {
  width: 50%;
  max-width: 200px;
}

.thumbailfile-icon-button {
  position: absolute;
  top: 10%;
  right: 10%;
}
