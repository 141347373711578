.dni-rows {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0px;
}

.multiple-dni-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 400px;
}