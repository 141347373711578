.custom-pn-timeline-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: -0.5rem; /* $spacing-x-small: 0.5rem */
  margin-top: -0.5rem; /* $spacing-x-small: 0.5rem */
}

.custom-pn-timeline-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-bottom: 0.5rem; /* $spacing-x-small: 0.5rem */
  padding-top: 0.5rem; /* $spacing-x-small: 0.5rem */
  position: relative;
}

.custom-pn-timeline-item::before,
.custom-pn-timeline-item::after {
  background-color: #d1d1d1; /* $neutral-surface-highlight: {color.light.neutral.90} */
  height: 50%;
  left: 11px;
  position: absolute;
  width: 2px;
  z-index: 300;
}

.custom-pn-timeline-item:not(:first-of-type)::before {
  content: '';
  top: 0;
}

.custom-pn-timeline-item:not(:last-of-type)::after {
  bottom: 0;
  content: '';
}

.custom-pn-timeline-icon {
  align-items: center;
  border-radius: 100%;
  display: flex;
  height: 1.5rem; /* $spacing-medium: 1.5rem */
  justify-content: center;
  margin-right: 0.5rem; /* $spacing-x-small: 0.5rem */
  min-height: 1.5rem; /* $spacing-medium: 1.5rem */
  min-width: 1.5rem; /* $spacing-medium: 1.5rem */
  width: 1.5rem; /* $spacing-medium: 1.5rem */
  z-index: 301;
}

.custom-pn-timeline-icon--default {
  background-color: #d1d1d1; /* $neutral-surface-highlight: {color.light.neutral.90} */
}

.custom-pn-timeline-icon--primary {
  background-color: #96c1fc; /* $primary-surface-highlight: {color.light.primary.90} */
}

.custom-pn-timeline-icon--secondary {
  background-color: #d1d1d1; /* $neutral-surface-highlight: {color.light.neutral.90} */
}

.custom-pn-timeline-icon--danger {
  background-color: #f77a7c; /* $danger-surface-highlight: {color.light.danger.90} */
}

.custom-pn-timeline-icon--warning {
  background-color: #f7c77a; /* $warning-surface-highlight: {color.light.warning.90} */
}

.custom-pn-timeline-icon--success {
  background-color: #7af7c7; /* $success-surface-highlight: {color.light.success.90} */
}

.custom-pn-timeline-no-icon {
  background-color: #d1d1d1; /* $neutral-surface-highlight: {color.light.neutral.90} */
  border-radius: 100%;
  height: 0.5rem; /* $spacing-x-small: 0.5rem */
  margin-left: 0.5rem; /* $spacing-x-small: 0.5rem */
  margin-right: 1rem; /* $spacing-small: 1rem */
  min-height: 0.5rem; /* $spacing-x-small: 0.5rem */
  min-width: 0.5rem; /* $spacing-x-small: 0.5rem */
  width: 0.5rem; /* $spacing-x-small: 0.5rem */
}

.custom-pn-timeline-item__details {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.custom-pn-timeline-item__info,
.custom-pn-timeline-item__timestamp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-pn-timeline-item__info {
  flex: 1 1 auto;
}

.custom-pn-timeline-item__timestamp {
  flex: 0 0 auto;
}
